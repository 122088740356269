$white      : #ffffff;
$whiteop    : rgba(#ffffff, 0.35);
$black      : #000000;
$blue       : #00346f;
$orange     : #ec7404;
$yellow     : #eeb21d;
$yellowmuted: rgba(#eeb21d, 0.35);
$green      : #93bd4c;
$grey 		: #5c5b5b;
$muted 		: #a4d3f3;
$lightmuted : #eff7fd;

$colors: (
	'white'      : $white,
	'whiteop'    : $whiteop,
	'black'      : $black,
	'blue'       : $blue,
	'orange'     : $orange,
	'yellow'     : $yellow,
	'yellowmuted': $yellowmuted,
	'green'      : $green,
	'grey'		 : $grey,
	'muted'		 : $muted,
	'lightmuted' : $lightmuted,
);

@each $index, $color in $colors{
	.bg--#{$index}{
		background-color:		$color;
		transition: 			background-color 0.2s ease;
	}

	.color--#{$index}{
		color: 					$color;
		transition: 			color 0.2s ease;

		svg{
			fill: $color;
			transition: fill 0.2s ease;
		}

		.arrow-icon{
			border-color: 	$color;
			transition: border 0.2s ease
		}
	}

	.border-color--#{$index}{
		border-color: 			$color !important;
	}

	.hover-color--#{$index}{
		&:hover{
			color: 				$color !important;
			
			svg{
				fill: $color !important;
			}

			.arrow-icon{
				border-color: 	$color !important;
			}
		}
	}

	.hover-bg--#{$index}{
		&:hover{
			background:			$color !important;
		}
	}
}