// snelle breakpoint syntax
@import 'breakpoint-sass';
@include breakpoint-set( 'default feature', 'max-width' );

// for sass breakpoints
$bp-sm: 639px;
$bp-md: 959px;
$bp-lg: 1199px;
$bp-xl: 1599px;

// container

.container{
	max-width: 1280px;
	padding-left: 24px;
	padding-right: 24px;

	@include breakpoint($bp-xl){
		padding-left: 24px;
		padding-right: 24px;
	}

	@include breakpoint($bp-md){
		padding-left: 20px;
		padding-right: 20px;
	}
}

@import "colors",
"fonts",
"layout",
"typography",
"buttons",
"forms",
"modules",
"header",
"footer",
"slider";