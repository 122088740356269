.icon-white{
	align-items: center;
	border-radius: 100%;
	border: 1px solid $white;
	display: flex;
	font-size: 12px;
	height: 24px;
	justify-content: center;
	width: 24px;
}

.icon-primary{
	align-items: center;
	border: 1px solid $blue;
	border-radius: 100%;
	color: $blue;
	display: flex;
	font-size: 12px;
	height: 24px;
	justify-content: center;
	width: 24px;
}

.button{
	background: $white;
	border: none;
	border-radius: 8px;
	box-shadow: 0px 3px 15px rgba(0,0,0,0.12);
	color: $blue;
	display: block;
	font-size: 16px;
	padding: 12px 18px;
	text-align: center;
	transition: all 0.2s ease;

	@include breakpoint($bp-md){
		font-size: 13px;
		padding: 8px 6px;
	}

	&:hover{
		background: $blue;
		color: $white;
	}

	&--green{
		background: $green;
		color: $white;

		&:hover{
			background: $white;
			color: $green;
		}
	}

	&--orange{
		background: $orange;
		color: $white;

		&:hover{
			background: $white;
			color: $orange;
		}
	}

	&--muted{
		background: $muted;
		color: $white;

		&:hover{
			background: $white;
			color: $blue;
		}
	}
}

.button-link{
	background: none;
	border: none;
	color: $blue;

	&:hover{
		color: lighten($blue, 15);
	}
	font-weight: bold;
}