  * {
  	scrollbar-width: auto;
  	scrollbar-color: $blue #ffffff;
  }

  *::-webkit-scrollbar {
  	width: 6px;
  }

  *::-webkit-scrollbar-track {
  	background: #dddddd;
  }

  *::-webkit-scrollbar-thumb {
  	background-color: $muted;
  	border-radius: 10px;
  	border: 3px solid $blue;
  }

  .z-index-1{
  	z-index: 1;
  }

  .ratio-16-9{
  	padding-bottom: calc(9/16 * 100%);
  }

  .ratio-4-3{
  	padding-bottom: calc(3/4 * 100%);
  }

  .inspectie__bg{
  	display: flex;
  	justify-content: center;
  	img{
  		margin-top: 200px;
  		height: calc(100% - 200px);
      max-width: 100vw;
  		z-index: -1;
  	}
  }

  .analyse__bg{
    object-fit: fit;
  	bottom: 0;
  	left: 0;
  	position: absolute;
  	right: 0;
  	top: 160px;
  	width: 100%;
    height: calc(100% - 160px);
  	z-index: -1;
  }

  .investering__bg{
  	bottom: 0;
  	left: 0;
  	position: absolute;
  	right: 0;
  	top: -1vw;
  	width: 100%;
  	z-index: -1;
  }

  .tabs{
  	background: $white;
  	position: sticky;
  	top: 60px;
  	z-index: 5;

  	&:before{
  		background: $white;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
  		content: '';
  		height: 100%;
  		left: -20px;
  		position: absolute;
  		right: -20px;
  		top: 0;
  		width: calc(100% + 40px);
  		z-index: -1;

      // @include breakpoint($bp-md){
      //   left: -20px;
      //   right: -20px;
      //   width: calc(100% + 40px);
      // }
  	}
  }

  .tabs__item{
  	display: none;

  	&--active{
  		display: block;
  	}
  }

  .tab-button{
  	&--active{
  		background-color: $blue;
  		color: $white;
  	}
  }

  .panel{
  	display: none;
  	height: 100vh;
  	left: 0;
  	min-height: 100vh;
  	position: fixed;
  	right: 0;
  	justify-content: center;
  	top: 0;
  	width: 100%;
  	z-index: 999;

  	&__inner{
  		align-items: center;
  		display: flex;
  		flex-direction: column;
  		overflow: auto;
  		padding: 50px;
  		width: 100%;
  	}

  	&__backlayer{
  		background: rgba($black, 0.7);
  		bottom: 0;
  		left: 0;
  		position: absolute;
  		right: 0;
  		top: 0;
  		z-index: -1;
  	}

  	&__logo {
  		align-items: center;
  		background: $white;
  		border-radius: 100%;
  		box-shadow: 0px 3px 15px rgba(0,0,0,0.12);
  		display: flex;
  		height: 80px;
  		justify-content: center;
  		margin-bottom: 40px;
  		min-height: 80px;
  		min-width: 80px;
  		width: 80px;
  	}

  	&__close{
  		background: $white;
  		box-shadow: 0px 3px 15px rgba(0,0,0,0.12);
  		border: none;
  		border-radius: 100%;
  		height: 40px;
  		opacity: 1;
  		position: fixed;
  		right: 50px;
  		top: 50px;
  		transform: scale(1);
  		transition: all 0.2s ease;
  		width: 40px;

  		&:hover{
  			transform: scale(1.1);
  		}
  	}

  	&__body{
  		background: $white;
  		border-radius: 15px;
  		max-width: 824px;
  		padding: 2rem;
  	}

  	@include breakpoint($bp-md){
  		background: $white;
  		padding: 0;

  		&__close{
  			top: 24px;
  			right: 24px;
  		}

  		&__inner{
  			overflow: auto;
  			padding: 24px 0;
  			max-height: 100vh;
  		}

  		&__backlayer{
  			display: none;
  		}
  	}

  	&--active{
  		display: flex;
  	}
  }

  #signaturepad{
  	border: 1px solid rgba($grey, 0.6);
  	border-radius: 15px;
  	cursor: crosshair;
  }

  #signaturepad-clear{
  	color: $grey;
  	background: none;
  	border: none;
  	padding: 0;
  	text-decoration: underline;
  }

  .custom-checkbox{
  	label{
  		cursor: pointer;
  		display: block;
  		padding-left: 36px;
  		position: relative;

  		&:before{
  			border: 1px solid $blue;
  			border-radius: 3px;
  			content: '';
  			height: 16px;
  			left: 0;
  			position: absolute;
  			top: 2px;
  			width: 16px;
  		}

  		&:after{
  			border-bottom: 2px solid $blue;
  			border-left: 2px solid $blue;
  			content: '';
  			display:inline-block;
  			height:4px;
  			left: 3px;
  			opacity: 0;
  			position: absolute;
  			top: 6px;
  			transform: rotate(-45deg);
  			width: 9px;
  		}    

  		&:hover:after{
  			opacity: 0.3;
  		}
  	}

  	input:checked + label:after{
  		opacity: 1;
  	}
  }

  .accordion{
  	&__header{
  		button{
  			background: none;
  			border: none;
  			display: none;
  			padding: 0;
  		}
  	}

  	@include breakpoint($bp-md){
  		&__header{
  			cursor: pointer;
  			button{
  				display: block;
  				img,svg{
  					transform: rotate(0deg);
  					transition: all 0.2s ease;
  				}
  			}
  		}

  		&__body{
        height: 0;
        overflow: hidden;
        padding: 0;
        transition: all 0.5s ease;
  		}

  		&--active{
  			.accordion__header{
  				img,svg{
            margin-bottom: 2px;
  					transform: rotate(180deg);
  				}
  			}

  			.accordion__body{
  				height: inherit;
  				padding-top: 18px;
  			}
  		}
  	}
  }

  .scroll-up{
    opacity: 0;
    position: fixed;
    bottom: 70px;
    right: 25px;
    transition: all 0.2s ease;

    svg, img{
      margin-top: 5px;
      margin-left: 12px;
      height: 25px;
      width: 20px;
    }

    &--active{
      opacity: 1;
    }

    @include breakpoint($bp-md){
      bottom: 60px;
      right: 20px;
    }
  }

  .bedankt-intro{
    &__inner{
      min-height: calc(100vh - 120px);
    }    
  }

  .socials{
    background: rgba($blue, 0.9);
    position: relative;

    .image{
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
  }