@font-face {
  font-display: swap;
  font-family : 'Frutiger';
  font-weight : 300;
  src: url("../fonts/FrutigerLTStd-Light.eot?") format("eot"),
		 url("../fonts/FrutigerLTStd-Light.woff2") format("woff2"),
		 url("../fonts/FrutigerLTStd-Light.woff") format("woff"),
		 url("../fonts/FrutigerLTStd-Light.ttf") format("truetype"),
		 url("../fonts/FrutigerLTStd-Light.svg#FrutigerLTStd-Light") format("svg");
}

@font-face {
  font-display: swap;
  font-family : 'Frutiger';
  font-weight : 400;
  src: url("../fonts/FrutigerLTStd-Roman.eot?") format("eot"),
		 url("../fonts/FrutigerLTStd-Roman.woff2") format("woff2"),
		 url("../fonts/FrutigerLTStd-Roman.woff") format("woff"),
		 url("../fonts/FrutigerLTStd-Roman.ttf") format("truetype"),
		 url("../fonts/FrutigerLTStd-Roman.svg#FrutigerLTStd-Roman") format("svg");
}

@font-face {
  font-display: swap;
  font-family : 'Frutiger';
  font-weight : 700;
  src: url("../fonts/FrutigerLTStd-Bold.eot?") format("eot"),
		 url("../fonts/FrutigerLTStd-Bold.woff2") format("woff2"),
		 url("../fonts/FrutigerLTStd-Bold.woff") format("woff"),
		 url("../fonts/FrutigerLTStd-Bold.ttf") format("truetype"),
		 url("../fonts/FrutigerLTStd-Bold.svg#FrutigerLTStd-Bold") format("svg");
}

@font-face {
  font-display: swap;
  font-family : 'Frutiger';
  font-weight : 800;
  src: url("../fonts/FrutigerLTStd-Black.eot?") format("eot"),
		 url("../fonts/FrutigerLTStd-Black.woff2") format("woff2"),
		 url("../fonts/FrutigerLTStd-Black.woff") format("woff"),
		 url("../fonts/FrutigerLTStd-Black.ttf") format("truetype"),
		 url("../fonts/FrutigerLTStd-Black.svg#FrutigerLTStd-Black") format("svg");
}

.frutiger{
	font-family: 'Frutiger', sans-serif;
}