*{
	font-family: 'Frutiger', sans-serif;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5{
	color: $blue;
	line-height: 125%;
	margin-top: 0;
}

h1, .h1{
	font-size: 34px;
	font-weight: 800;
	margin-bottom: 18px;
}

h2, .h2{
	font-weight: 700;
	font-size: 24px;
	margin-bottom: 18px;
}

h3, .h3{
	font-size: 20px;
	font-weight: 700;
	margin-bottom: 18px;
}

@include breakpoint($bp-md){
	h1, .h1{
		font-size: 26px;
	}

	h2, .h2{
		font-size: 22px;
	}

	h3, .h3{
		font-size: 18px;
	}
}

hr{
	border: none;
	border-top: 1px solid rgba($blue, 0.35);
}

em{
	color: $orange;
	font-style: normal;
}

a{
	text-decoration: none;
}

p, .cms{
	color: $grey;
	font-size: 16px;
	font-weight: 300;
	line-height: 150%;
	margin-bottom: 0;
	margin-top: 0;

	a{
		color: $blue;
		text-decoration: underline;
		text-decoration-thickness: 2px;
		text-underline-offset: 4px;

		&:hover{
			color: darken($blue, 10);
		}
	}

	strong{
		color: $blue;
	}

	@include breakpoint($bp-md){
		font-size: 14px;
	}
}

p + p{
	margin-top: 18px;
}

$fontsizes: (
	12,
	14,
	15,
	16,
	17,
	18,
	24,
	34,
	);

@each $fontsize in $fontsizes{
	.fs--#{$fontsize}{
		font-size: #{$fontsize}px;

		@include breakpoint($bp-md){
			font-size: #{$fontsize - 2}px;
		}
	}
}

$lineheights: (
	75,
	100,
	125,
	150,
	175,
	);

@each $lnh in $lineheights{
	.lnh--#{$lnh}{
		line-height: percentage($lnh/100);
	}
}

.text-italic{
	font-style: italic;
}

.text-bold{
	font-weight: 600;
}

.text-regular{
	font-weight: 400;
}

.text-light{
	font-weight: 300;
}

.text-uppercase{
	text-transform: uppercase;
}

.text-normal{
	text-transform: inherit;
}

.text-left{
	text-align: left;
}

.text-right{
	text-align: right;
}

.text-center{
	text-align: center;
}

.text-tablet-center{
	@include breakpoint($bp-md){
		text-align: center;
	}
}

.text-mobile-center{
	@include breakpoint($bp-sm){
		text-align: center;
	}
}

.text-decoration-none{
	text-decoration: none;
}

.td-underline{
	text-decoration: underline;
}

.lst-none{
	list-style-type: none;
}

.column-count-2{
	column-count: 2;
	column-gap: 40px;

	@include breakpoint($bp-md){
		column-count: 1;
	}
}

.arrow-icon{
	align-items: center;
	border: 2px solid $blue;
	border-radius: 100%;
	display: flex;
	height: 24px;
	justify-content: center;
	padding-top: 1px;
	position: relative;
	width: 24px;
}

.pricing-table{
	th, td{
		font-size: 15px;
		font-weight: 300;
		padding: 6px 0;
		text-align: left;
	}

	th{
		color: $muted;
		font-weight: 600;
		text-transform: uppercase;
		text-align: right;
		font-size: 12px;

		&:first-child{
			text-align: left;
		}

		&:last-child{
			padding-right: 1.4rem;
		}
	}

	td{
		text-align: right;
		min-width: 90px;

		&:first-child{
			text-align: left;
		}

		&:nth-child(2){
			min-width: 60px;
		}

		&:last-child{
			padding-right: 1.4rem;
		}
	}

	@include breakpoint($bp-md){
		th, td{
			font-size: 12px;
		}

		th{
			font-size: 10px;
		}
	}
}

hr.hr--white{
    border-color: rgba(255, 255, 255, 0.55);
}