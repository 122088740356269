.review-slider, .werkwijze-slider, .company-slider{
	.slider-arrow{
		background: none;
		border: none;
		font-size: 28px;
		color: $blue;
		position: absolute;
		left: -60px;
		top: calc(50% - 10px);

		&--right{
			left: auto;
			right: -60px;
		}

		@include breakpoint($bp-md){
			font-size: 20px;
			left: -10px;

			&--right{
				left: auto;
				right: -10px;
			}
		}
	}
}

.werkwijze-slider{
	.slider-arrow{
		top: calc(50% - 22px);
	}
}

.company-slider{
	.company-item{
		height: 100%;
		& > div{
			height: 100%;
		}
		ul{
			list-style-type: none;
			padding-left: 0;
			li{
				color: $grey;
				font-weight: 300;
				padding-left: 24px;
				position: relative;

				&:before{
					border-bottom: 2px solid $green;
					border-left: 2px solid $green;
					content: '';
					display:inline-block;
					height:4px;
					left: 3px;
					position: absolute;
					top: 6px;
					transform: rotate(-45deg);
					width: 9px;
					
				}
			}
		}
	}
}

.advantages-slider, .werkwijze2-slider{
	.slider-arrow{
		background: none;
		border: none;
	}

	.glide__slide{
		.icon{
			width: 70px;
			height: 70px;
			border-radius: 100%;
			transform: scale(1);
			transition: all 0.2s ease;
		}
		&--active{
			.icon{ 
				transform: scale(1.5);
			}
		}
	}

	.advantage-item{
		cursor: pointer;
	}
}

.advantages-slider > .glide__track{
	@include breakpoint($bp-sm){
		width: 120vw;
		margin-left: -16vw;
	}
}

.advantages-textslider{
	&__item{
		display: none;

		&--active{
			display: block;
		}
	}
}