.home{
	padding-top: 400px;
	transition: all 0.2s ease;

	@include breakpoint($bp-md){
		padding-top: 60px;
	}
}

.header{
	background: $blue;
	height: 400px;
	overflow: hidden;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	max-width: 100vw;
	transition: all 0.2s ease;
	z-index: 11;

	&__bg{
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;

		.image{
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}

		&:after{
			background: rgba($blue, 0.8);
			content: '';
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}

		.overlay{
			height: 100%;
			left: 0;
			object-fit: fill;
			position: absolute;
			right: 0;
			top: 0;
			transition: all 0.2s ease;
			width: 100%;
			z-index: 1;
		}
	}

	&--sticky{
		height: 60px;

		.overlay{
			top: 60px
		}
	}

	@include breakpoint($bp-md){
		height: auto;

		&__bg{
			.overlay{
				display: none;
			}
		}
	}

	.logo{
		position: relative;
		z-index: 10;

		svg, img{
			display: block;
		}
	}

	.navbar{
		list-style-type: none;
		position: relative;
		z-index: 9;

		&__item{
			color: $white;
			display: block;
			font-size: 16px;
			position: relative;

			&:before{
				background: $white;
				content: '';
				height: 2px;
				left: 0;
				position: absolute;
				top: 100%;
				transform: scaleX(0);
				transition: all 0.2s ease;
				width: 100%;
			}

			&:hover{
				&:before{
					transform: scaleX(1);
				}
			}
		}
	}

	.hamburger{
		background: none;
		border: none;
		cursor: pointer;
		padding: 10px 0;
		position: relative;
		z-index: 10;

		.line{
			background: $white;
			display: block;
			height: 2px;
			transition: all 0.2s ease;
			width: 30px;
		}

		&:before, &:after{
			background: $white;
			content: '';
			display: block;
			height: 2px;
			position: absolute;
			left: 0;
			transition: all 0.2s ease;
			width: 30px;
		}

		&:before{
			top: 0;
		}

		&:after{
			bottom: 0;
		}
	}

	&--open-nav{
		.hamburger{
			.line{
				opacity: 0;
			}

			&:before{
				opacity: 1;
				top: 10px;
				transform: rotate(45deg);
			}

			&:after{
				bottom: 10px;
				opacity: 1;
				transform: rotate(-45deg);
			}
		}

		.navbar{
			align-items: stretch !important;
			background: $blue;
			display: flex !important;
			flex-direction: column;
			height: 100vh;
			justify-content: center !important;
			left: 0;
			margin-top: 0;
			padding: 50px 1.2rem;
			position: fixed;
			top: 0;
			width: 100%;

			&__bg{
				opacity: 0.3;
			}

			&__item{
				border-bottom: 1px solid rgba($white, 0.5);
				display: block;
				font-weight: bold;
				padding: 16px 0 !important;
				text-align: left;
				text-transform: uppercase;
				width: 100%;

				&:before{
					display: none;
				}
			}

			button{
				margin-top: 16px !important;
				z-index: 5;
			}
		}
	}
}

.after-header{
	left: 50%;
	margin-bottom: 50px;
	margin-top: -250px;
	max-width: 500px;
	position: relative;
	transition: all 0.2s ease;
	width: calc(50% - 12px);
	z-index: 11;
}

.main--sticky{
	.after-header{
		position: relative;
		margin-top: 50px;
		z-index: 12;
	}
}

.main--sticky-far{
	.after-header{
		z-index: 9;
	}
}