*{
	box-sizing: border-box;
}

html{
	-webkit-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html, body{
	border: 0;
	color: $black;
	font-family: 'Roboto', sans-serif;
	margin: 0;
	padding: 0;
	position: relative;
}

body{
	// overflow-x: hidden; 
}

.main{
	&--sticky{
		padding-top: 150px !important;

		@include breakpoint($bp-md){
			padding-top: 60px !important;
		}
	}
}

.zindex12{
	z-index: 12;
}


button{
	cursor: pointer;
}

img[src=""], img:not([src]){
  opacity: 0;
}

img[data-svg] + svg{
  animation: 0.5s ease fadein forwards;
  box-sizing: border-box;
  fill: currentcolor;
  height: auto;
  max-width: 100%;
  transform: translate(0, 0);
}

img[data-svg] + svg [fill*="#"] {
  fill: currentcolor;
}

.lazyload, .lazyloading{
	opacity: 0;
	transition: all 0.1s ease;
}

svg.lazyload{
	opacity: 1;
}

.lazyloaded{
	opacity: 1;
	transition: all 0.1s ease;
}

main{
	// overflow: hidden;
	flex: 1;
	min-width: 0;
	position: relative;
	z-index: 1;
}

@include breakpoint($bp-md){
	body > .container{
		// overflow: hidden;
	}

	main{
		overflow: inherit;
	}
}

// boxes

.border-radius{
	border-radius: 15px;
}

.box-shadow{
	box-shadow: 0px 3px 15px rgba(0,0,0,0.12);
}

.border{
	border: 2px solid $black;
}

// sections

.section{
	padding-bottom: 110px;
	padding-top: 110px;

	&-large{
		padding-bottom: 175px;
		padding-top: 175px;
	}

	&-small{
		padding-bottom: 75px;
		padding-top: 75px;
	}

	@include breakpoint('1279px'){
		padding-bottom: 80px;
		padding-top: 80px;

		&-large{
			padding-bottom: 150px;
			padding-top: 150px;
		}

		&-small{
			padding-bottom: 65px;
			padding-top: 65px;
		}
	}

	@include breakpoint('767px'){
		padding-bottom: 75px;
		padding-top: 75px;

		&-large{
			padding-bottom: 100px;
			padding-top: 100px;
		}

		&-small{
			padding-bottom: 75px;
			padding-top: 75px;
		}
	}
}

.overflow-hidden{
	overflow: hidden;
}

.overflow-x-hidden{
	overflow-x: hidden;
}

// positions

.position-relative{
	position: relative;
}

.position-cover{
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.rotate-90{
	transform: rotate(90deg) !important;
}

.rotate-180{
	transform: rotate(180deg) !important;
}

.rotate-270{
	transform: rotate(270deg) !important;
}

svg.rotate-90{
	margin-left: -2px;
}

svg.rotate-270{
	margin-left: 2px;
}

svg.rotate-180{
	margin-top: -2px;
}

// backgrounds

.background-cover{
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.ratio-small-banner{
	padding-bottom: calc((1/4) * 100%);
}

.bg--gradient-left{
	background: linear-gradient(90deg, rgba(0,0,0,0.02) 0%, rgba(0,0,0,0.001) 150px);
}

.bg--md-gradient-none{
	@include breakpoint($bp-md){
		background: none;
	}
}

// flex

.flex-1{
	flex: 1;
}

.height-1-1{
	height: 100%;
}

.min-height-1-1{
	min-height: 100%;
}

.height-1-2{
	height: 50%;
}

.width-1-1{
	width: 100%;
}

.max-width-1-1{
	max-width: 100%;
}

.width-1-2{
	width: 50%;
}

@media only screen and (min-width: 961px){
	.height-md-1-1{
		height: 100%;
	}

	.height-md-1-2{
		height: 50%;
	}

	.width-md-1-1{
		width: 100%;
	}

	.width-md-1-2{
		width: 50%;
	}
}

@media only screen and (min-width: 1201px){
	.height-lg-1-1{
		height: 100%;
	}

	.height-lg-1-2{
		height: 50%;
	}

	.width-lg-1-1{
		width: 100%;
	}

	.width-lg-1-2{
		width: 50%;
	}
}